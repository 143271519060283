import Button, { ButtonSizes, ButtonStyle } from '@components/Button';
import { Column, RowSpace } from '@components/LinearLayout';
import {
  type Booking,
  type BookingCheckDetails,
  type BookingsFormInput,
  type DiscountType,
  type EventType,
  type PaymentMethodType,
  type PublicBookingsCheckInput,
  type QueryResponseType,
  type Ticket,
} from '@hubs101/booking-api-skd-client/lib/types';
import { usePublicBookings, type TicketOptionsInput } from '@utils/apiHooks';
import {
  useCallback,
  useEffect,
  useMemo,
  type Dispatch,
  type SetStateAction,
} from 'react';
import {
  type Control,
  type FieldErrorsImpl,
  type UseFormGetValues,
  type UseFormHandleSubmit,
  type UseFormSetValue,
  type UseFormTrigger,
} from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  AttendeeDetailsRoot,
  CenterItem,
  EventAddress,
  EventDates,
  EventTitle,
  HeaderRightWrapper,
  LeftSideWrapper,
  PriceBody,
  RightBody,
  RightSideWrapper,
  Summary,
} from './styles';

import BookingDetailsSection from '@components/BookingDetailsSection';
import BoxDecoration from '@components/BoxDecoration';
import ErrorSection from '@components/ErrorSection';
import { H5 } from '@components/Typography';
import ExpandableInput from '@composites/ExpandableInput';
import CustomerDetailsForm from '@pages/Purchase/components/CustomerDetailsForm';
import { extraContentMapper } from '@utils/extraContentPaymentMethods';
import { mapPaymentTypeToTranslation } from '@utils/mapPaymentTypeToTranslation';
import { formatDateTimeZoned } from '@utils/time';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface AttendeeDetailsStepProps {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  control: Control<BookingsFormInput>;
  errors: Partial<FieldErrorsImpl<BookingsFormInput>>;
  trigger: UseFormTrigger<BookingsFormInput>;
  getValues: UseFormGetValues<BookingsFormInput>;
  handleSubmit: UseFormHandleSubmit<BookingsFormInput>;
  setValue: UseFormSetValue<BookingsFormInput>;
  tickets: Ticket[];
  paymentMethods: PaymentMethodType[];
  event: EventType;
  discount?: DiscountType;
  selectedPayment: PaymentMethodType | undefined;
  setSelectedPayment: Dispatch<SetStateAction<PaymentMethodType | undefined>>;
  errorBookings: string;
  setErrorBookings: Dispatch<SetStateAction<string>>;
  handleErrorsBookings: (error: Error) => void;
  bookingsCheckDetails: QueryResponseType<BookingCheckDetails>;
  bookingCheckInput: PublicBookingsCheckInput;
  setBookingData: Dispatch<SetStateAction<Booking | null>>;
  selectedTicketsWithOptions: TicketOptionsInput[];
  setSelectedTicketsWithOptions: Dispatch<SetStateAction<TicketOptionsInput[]>>;
}

const AttendeeDetailsStep = ({
  step,
  setStep,
  control,
  errors,
  trigger,
  getValues,
  setValue,
  handleSubmit,
  tickets,
  paymentMethods,
  event,
  discount,
  selectedPayment,
  setSelectedPayment,
  errorBookings,
  handleErrorsBookings,
  setErrorBookings,
  bookingsCheckDetails,
  bookingCheckInput,
  setBookingData,
  selectedTicketsWithOptions,
  setSelectedTicketsWithOptions,
}: AttendeeDetailsStepProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get('ty');
  const language = searchParams.get('la') ?? 'en';
  const isIframe = useMemo(() => type === 'iframe', [type]);
  const bookingsCheck = bookingsCheckDetails.data;
  const isBookingsCheckLoading = bookingsCheckDetails?.loading;
  const mutateBookingsCheck = bookingsCheckDetails?.mutate;

  const noPaymentMethodsError =
    paymentMethods &&
    paymentMethods.length === 0 &&
    bookingsCheck &&
    bookingsCheck.total > 0
      ? t('no-payment-methods')
      : '';
  const errorDescription = errorBookings || noPaymentMethodsError;

  const {
    data: bookingDataResult,
    mutate: mutateBookings,
    status,
    reset,
  } = usePublicBookings(bookingCheckInput, language, handleErrorsBookings);
  const bookingData = bookingDataResult?.bookings?.result?.data;
  const isBookingLoading = status === 'pending';
  const location = useMemo(
    () =>
      [
        event?.location?.name,
        event?.location?.marker?.city,
        event?.location?.marker?.country
          ? t(`countries.${event?.location?.marker?.country ?? ''}`)
          : '',
      ]
        .filter(locationEntry => locationEntry)
        .join(', '),
    [
      event?.location?.marker?.city,
      event?.location?.marker?.country,
      event?.location?.name,
      t,
    ]
  );
  const checkShouldShowPaymentMethods = !(
    paymentMethods?.length === 0 || bookingsCheck?.total === 0
  );

  const handlePlaceOrder = useCallback(() => {
    setErrorBookings('');
    const assigned = {
      first_name: getValues().first_name,
      last_name: getValues().last_name,
      company_name: getValues().company_name,
      email: getValues().contact_email,
    };
    const newTicketsWithOptionsSelected = selectedTicketsWithOptions.map(
      item => {
        if (item.isAssignedToMe) {
          return { ...item, assigned };
        }
        return item;
      }
    );

    mutateBookings({
      bookingCheck: {
        ...bookingCheckInput,
        tickets: newTicketsWithOptionsSelected,
      },
      language,
    });
  }, [
    bookingCheckInput,
    getValues,
    language,
    mutateBookings,
    selectedTicketsWithOptions,
    setErrorBookings,
  ]);

  useEffect(() => {
    if (bookingDataResult?.bookings?.status === 200) {
      if (bookingData?.payment_url) {
        window.open(bookingData?.payment_url, '_self');
      } else {
        setBookingData(bookingData);
        setStep(step + 1);
        reset();
      }
    }
  }, [
    bookingData,
    bookingDataResult?.bookings?.status,
    navigate,
    reset,
    setBookingData,
    setStep,
    step,
  ]);

  return (
    <>
      <ErrorSection description={errorDescription} />
      <AttendeeDetailsRoot>
        <LeftSideWrapper>
          <BoxDecoration
            padding="2rem"
            bgColor={theme.color.white}
            borderRadius={theme.borderRadius.radius5}
            width="100%"
            border={`0.1rem solid ${theme.color.grey1}`}
          >
            <Column gap="2rem">
              <H5 id="customer-details">{t('customer-details')}</H5>
              <CustomerDetailsForm
                control={control}
                errors={errors}
                trigger={trigger}
                setValue={setValue}
                getValues={getValues}
                mutateBookingsCheck={mutateBookingsCheck}
              />
            </Column>
          </BoxDecoration>
          {/* <BoxDecoration
            padding="2rem"
            bgColor={theme.color.white}
            borderRadius={theme.borderRadius.radius5}
            width="100%"
            border={`0.1rem solid ${theme.color.grey1}`}
          >
            <Column gap="2rem">
              <H5 id="tickets-and-extras">{t('tickets-and-extras')}</H5>
              {selectedTicketsWithOptions?.map((selectedTicket, index) => (
                <TicketOptionsItem
                  key={`${selectedTicket?.ticket ?? ''}-${index}`}
                  tickets={tickets}
                  order={index}
                  selectedTicket={selectedTicket}
                  selectedTicketsWithOptions={selectedTicketsWithOptions}
                  setSelectedTicketsWithOptions={setSelectedTicketsWithOptions}
                />
              ))}
            </Column>
          </BoxDecoration> */}
          {checkShouldShowPaymentMethods && (
            <BoxDecoration
              padding="2rem"
              bgColor={theme.color.white}
              borderRadius={theme.borderRadius.radius5}
              width="100%"
              border={`0.1rem solid ${theme.color.grey1}`}
            >
              <Column gap="2rem">
                <H5 id="payment-options">{t('payment-options')}</H5>
                {paymentMethods?.map(paymentMethod => (
                  <ExpandableInput
                    key={paymentMethod.id}
                    type="P"
                    isSelected={selectedPayment === paymentMethod}
                    extraContent={extraContentMapper(paymentMethod.type)}
                    title={mapPaymentTypeToTranslation(paymentMethod.type, t)}
                    onClick={() => {
                      setSelectedPayment(paymentMethod);
                    }}
                  />
                ))}
              </Column>
            </BoxDecoration>
          )}
        </LeftSideWrapper>
        <RightSideWrapper isIframe={isIframe}>
          <BoxDecoration
            data-testid="calculations:container"
            padding="0"
            bgColor={theme.color.white}
            borderRadius={theme.borderRadius.radius5}
            width="100%"
            border={`0.1rem solid ${theme.color.grey1}`}
          >
            <HeaderRightWrapper>
              <EventTitle>{event?.name}</EventTitle>
              <EventAddress>{location}</EventAddress>
              <EventDates>
                {formatDateTimeZoned(event?.start_date ?? '', event?.timezone)}
                <span> - </span>
                {formatDateTimeZoned(event?.end_date ?? '', event?.timezone)}
              </EventDates>
            </HeaderRightWrapper>
            <RightBody>
              <PriceBody gap="0.5rem">
                <Summary>{t('summary')}</Summary>
                <BookingDetailsSection
                  bookingsCheck={bookingsCheck}
                  isBookingsCheckLoading={!!isBookingsCheckLoading}
                  discount={discount}
                  selectedTicketsWithOptions={selectedTicketsWithOptions}
                  bookingsCheckDetails={bookingsCheckDetails}
                />
              </PriceBody>
              <Button
                dataTestId="book:book-now"
                showSpinner={isBookingLoading}
                buttonSize={ButtonSizes.medium}
                buttonStyle={ButtonStyle.default}
                backgroundColor={theme.color.green}
                disabled={
                  !navigator.onLine ||
                  Boolean(noPaymentMethodsError) ||
                  isBookingLoading
                }
                content={
                  <CenterItem>
                    {bookingsCheck?.total > 0
                      ? t('commit-to-order')
                      : t('book-now')}
                  </CenterItem>
                }
                onClick={() => {
                  if (navigator.onLine) {
                    void handleSubmit(handlePlaceOrder)();
                  }
                }}
              />
              <RowSpace />
            </RightBody>
          </BoxDecoration>
        </RightSideWrapper>
      </AttendeeDetailsRoot>
    </>
  );
};
export default AttendeeDetailsStep;
