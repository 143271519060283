/* eslint-disable @typescript-eslint/restrict-template-expressions */
export const ROOT_URL = process.env.ROOT_URL;
export const ROOT_PUBLIC_URL = process.env.ROOT_PUBLIC_URL;
export const ENVIRONMENT = process.env.NODE_ENV;
export const PUBLIC_ROOT_URL = `${process.env.ROOT_URL}/public`;
export const BASE_URL_UNAUTHORIZED = `${ROOT_URL}/v1`;
export const BASE_URL = `${ROOT_URL}/api/v1`;

export const API_PATH = {
  EVENTS: `${ROOT_PUBLIC_URL}/events`,
  PUBLIC_TICKETS: `${ROOT_PUBLIC_URL}/tickets`,
  PUBLIC_BOOKINGS: `${PUBLIC_ROOT_URL}/bookings`,
};
