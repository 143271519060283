import {
  LeftSideContent,
  LinkText,
  RightSideContent,
  RootFooterLayout,
} from './styles';

import { ENVIRONMENT } from '@constants/api';
import dayjs from 'dayjs';
import generatedGitInfo from '../../../../generatedGitInfo.json';
import { useElementSize } from '@utils/hooks';
import { useTranslation } from 'react-i18next';

const FooterLayout = () => {
  const { t } = useTranslation();
  const [squareRef] = useElementSize();
  console.log({ ENVIRONMENT });

  return (
    <RootFooterLayout ref={squareRef}>
      <LeftSideContent>
        Ⓒ {dayjs().format('YYYY')} we.CONECT Global Leaders GmbH V.
        {ENVIRONMENT === 'development' && generatedGitInfo.gitBranch}
      </LeftSideContent>

      <RightSideContent>
        <LinkText
          href={String(t('terms-use-link'))}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('terms-use')}
        </LinkText>
        <LinkText
          href={String(t('privacy-policy-link'))}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('privacy-policy')}
        </LinkText>
        <LinkText
          href={String(t('imprint-link'))}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('imprint')}
        </LinkText>
        <LinkText
          href={String(t('powered-hubs101-link'))}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('powered-hubs101')}
        </LinkText>
      </RightSideContent>
    </RootFooterLayout>
  );
};

export default FooterLayout;
