import CardContainerWithIcon from '@components/CardContainerWithIcon';
import { Column } from '@components/LinearLayout';
import {
  type Booking,
  type BookingsFormInput,
} from '@hubs101/booking-api-skd-client/lib/types';
import ShareEvent from '@pages/OrderDetails/components/ShareEvent';
import { type Dispatch, type SetStateAction } from 'react';
import { type Control, type FieldErrorsImpl } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MoreTickets } from './styles';
import { useHookParams } from '@utils/hooks';

interface PaymentDetailsStepProps {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  control: Control<BookingsFormInput>;
  errors: Partial<FieldErrorsImpl<BookingsFormInput>>;
  bookingData: Booking | null;
}

const PaymentDetailsStep = ({
  step,
  setStep,
  control,
  errors,
  bookingData,
}: PaymentDetailsStepProps) => {
  const { t } = useTranslation();
  const [quantity] = useHookParams(['qu']);

  return (
    <Column data-testid="success-page:payment-success" gap="2rem">
      <CardContainerWithIcon
        iconName="check"
        title={t('your-purchase-was-successful')}
        subtitle1={
          t('booking-confirmation-subtitle', {
            booking_number: bookingData?.code,
            booking_email: bookingData?.invoice?.email,
          }) ?? ''
        }
        boxShadowIcon="0px 2px 0px rgba(0, 0, 0, 0.1)"
        isLoading={!bookingData}
        dataTestId="success-page:success-card"
      />

      {(bookingData?.total !== 0 || quantity) && (
        <>
          <ShareEvent />
          <MoreTickets
            data-testid="success-page:buy-more-tickets"
            onClick={() => {
              setStep(0);
            }}
          >
            {t('buy-more-tickets')}
          </MoreTickets>
        </>
      )}
    </Column>
  );
};

export default PaymentDetailsStep;
