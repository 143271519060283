import {
  type DiscountType,
  type Ticket,
  type TicketItem,
} from '@hubs101/booking-api-skd-client/lib/types';
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import {
  Counter,
  CounterDispatchLeft,
  CounterDispatchRight,
  DiscountedPriceH6,
  LineThroughText,
  Price,
  QuantityPriceWrapper,
  TicketCardItemRoot,
  TicketNumber,
  TicketOptions,
  TicketQuantity,
  TicketTitle,
} from './styles';

import Icon from '@components/Icon';
import { P } from '@components/Typography';
import { CURRENCY } from '@constants/generic';
import { type TicketOptionsInput } from '@utils/apiHooks';
import { findDiscountedPriceOfSelectedTicket } from '@utils/findDiscountedPriceOfSelectedTicket';
import { formatPrice } from '@utils/fn';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useHookParams } from '@utils/hooks';

interface TicketCardItemProps {
  ticket: Ticket;
  discount?: DiscountType;
  selectedTickets?: TicketItem[];
  setSelectedTickets?: Dispatch<SetStateAction<TicketItem[]>>;
  selectedTicketsWithOptions: TicketOptionsInput[];
  setSelectedTicketsWithOptions: Dispatch<SetStateAction<TicketOptionsInput[]>>;
}

const TicketCardItem = ({
  ticket,
  discount,
  selectedTicketsWithOptions,
  setSelectedTicketsWithOptions,
}: TicketCardItemProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const [ticketQuantity] = useHookParams(['qu']);

  const symbol = (searchParams.get('cu') ??
    CURRENCY.EUR) as keyof typeof CURRENCY;

  const fullPrice = ticket?.price;

  const addTicket = useCallback(() => {
    if (ticketQuantity) return;
    setSelectedTicketsWithOptions([
      ...selectedTicketsWithOptions,
      {
        index: 0 + selectedTicketsWithOptions.length,
        isAssignedToMe: true,
        ticket: ticket.id,
        options: [],
      },
    ]);
  }, [
    selectedTicketsWithOptions,
    setSelectedTicketsWithOptions,
    ticket.id,
    ticketQuantity,
  ]);

  const deleteTicket = useCallback(() => {
    if (ticketQuantity) return;
    const lastIndex = selectedTicketsWithOptions.findLastIndex(
      item => item.ticket === ticket?.id
    );
    if (lastIndex === -1) return;
    const newSelectedTicketsWithOptions = selectedTicketsWithOptions
      ?.map((el, index) => {
        if (index > lastIndex) {
          return {
            ...el,
            index: index - 1,
          };
        } else if (index === lastIndex) {
          return null;
        }
        return el;
      })
      ?.filter(e => e) as TicketOptionsInput[];
    setSelectedTicketsWithOptions(newSelectedTicketsWithOptions);
  }, [
    selectedTicketsWithOptions,
    setSelectedTicketsWithOptions,
    ticket?.id,
    ticketQuantity,
  ]);

  const quantity = useMemo(
    () =>
      selectedTicketsWithOptions?.filter(item => item.ticket === ticket?.id)
        ?.length,
    [selectedTicketsWithOptions, ticket?.id]
  );

  const discountedPrice = findDiscountedPriceOfSelectedTicket(
    ticket?.id,
    fullPrice,
    discount
  );

  const isDiscounted =
    discountedPrice != null &&
    Number(discountedPrice) >= 0 &&
    fullPrice !== discountedPrice;

  return (
    <TicketCardItemRoot
      padding="1rem"
      borderRight={`0.1rem solid ${theme.color.greyMedium}`}
      borderTop={`0.1rem solid ${theme.color.greyMedium}`}
      borderBottom={`0.1rem solid ${theme.color.greyMedium}`}
      borderLeft={`0.3rem solid ${theme.color.greyDark}`}
      borderTopRightRadius={theme.borderRadius.radius10}
      borderBottomRightRadius={theme.borderRadius.radius10}
      boxShadow={`0 0.2rem 0 rgba(0, 0, 0, 0.1)`}
      data-testid={`tickets:ticket-name:${ticket.name}`}
    >
      <TicketTitle data-testid={`tickets:ticket:${ticket.name}:title`}>
        {ticket?.name}
      </TicketTitle>
      <TicketOptions>
        <P
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(ticket?.description ?? ''),
          }}
        />
      </TicketOptions>
      <QuantityPriceWrapper>
        <TicketQuantity>
          <Counter>
            <CounterDispatchLeft
              data-testid={`tickets:ticket:${ticket.name}:decrease`}
              onClick={deleteTicket}
            >
              -
            </CounterDispatchLeft>

            <TicketNumber>{quantity}</TicketNumber>
            <CounterDispatchRight
              data-testid={`tickets:ticket:${ticket.name}:increase`}
              onClick={addTicket}
            >
              +
            </CounterDispatchRight>
          </Counter>
        </TicketQuantity>
        <Icon name="close" color={theme.color.primaryText} />
        <Price data-testid={`tickets:ticket:${ticket.name}:price`}>
          {!isDiscounted || fullPrice === 0 ? (
            <>
              {fullPrice !== 0
                ? `${formatPrice(fullPrice, symbol)}`
                : t('free').toUpperCase()}
            </>
          ) : (
            <>
              <LineThroughText>
                {formatPrice(fullPrice, symbol)}
              </LineThroughText>
              <DiscountedPriceH6
                data-testid={`tickets:ticket:${ticket.name}:discounted`}
              >
                {discountedPrice !== 0
                  ? `${formatPrice(discountedPrice, symbol)}`
                  : t('free').toUpperCase()}
              </DiscountedPriceH6>
            </>
          )}
        </Price>
      </QuantityPriceWrapper>
    </TicketCardItemRoot>
  );
};

export default TicketCardItem;
