import {
  type CURRENCY_TYPE,
  LANGUAGE_INTL,
  type LANGUAGE_TYPE,
} from '@constants/generic';
import {
  type Ticket,
  type TicketItem,
} from '@hubs101/booking-api-skd-client/lib/types';

import { ROOT_URL } from '@constants/api';
import { THEME } from '@constants/theme';
import flatten from 'lodash/flatten';
import sortBy from 'lodash/sortBy';
import { type DefaultTheme } from 'styled-components';
import i18n from '../i18n';
import { useMediaQuery } from './hooks';
import { deploymentMediaLink } from './mappings';

export const isDarkTheme = (theme: DefaultTheme) =>
  theme.type.toUpperCase() === THEME.DARK;

export const publicMediaUrl = (url: string | undefined) => {
  return url ? `${deploymentMediaLink(ROOT_URL)}${url}` : url;
};

export const capitalizeFirstLetter = (S: string) => {
  if (S?.length === 0) return '';
  return S?.charAt(0).toUpperCase() + S?.slice(1);
};

export const useMobileWidth = () => useMediaQuery('(max-width: 480px)');

export const useTabletWidth = () => useMediaQuery('(max-width: 768px)');

export const useDesktopWidth = () => useMediaQuery('(min-width: 992px)');

export const useLargeDesktopWidth = () => useMediaQuery('(min-width: 1200px)');

export const extractSearchParams = (searchParams: URLSearchParams) => {
  let searchObj = {};
  searchParams.forEach((value, key) => {
    searchObj = { ...searchObj, [key]: value };
  });
  return searchObj;
};

export const filterSortParams = (params: Record<string, string | undefined>) =>
  Object.fromEntries(
    sortBy(
      Object.entries(params).filter(e => e[1]),
      e => e[1]
    )
  ) as Record<string, string>;

export const extractPreselectedTickets = (
  filteredSortedParams: Record<string, string>
) => {
  if (filteredSortedParams?.code) {
    return flatten(
      filteredSortedParams?.code
        .split(';')
        .map(item => item?.split(':'))
        .map(item => {
          const ticketQuantity = item?.[1]
            ? Array(Number(item?.[1]) ?? 1).fill(Number(item?.[1]) ?? 1)
            : [1];

          return ticketQuantity?.map(() => ({
            code: item[0],
            extraOptions: [],
            discountCode: item?.[2]?.length > 5 ? item?.[2] : '',
            id: null,
            discountValue: 0,
          }));
        })
    )?.filter(e => typeof e !== undefined);
  } else if (filteredSortedParams?.id) {
    return flatten(
      filteredSortedParams?.code
        .split(';')
        .map(item => item?.split(':'))
        .map(item => {
          const ticketQuantity = item?.[1]
            ? Array(Number(item?.[1]) ?? 1).fill(Number(item?.[1]) ?? 1)
            : [1];

          return ticketQuantity?.map(() => ({
            id: item[0],
            extraOptions: [],
            discountCode: item?.[2],
            code: null,
            discountValue: 0,
          }));
        })
    );
  } else return [];
};

export const filterTicketsByEvent = (
  unfilteredPreselectedTickets: TicketItem[],
  publicEventTickets: Ticket[],
  filteredSortedParams: Record<string, string>
) => {
  if (filteredSortedParams?.code) {
    const publicEventCodes = publicEventTickets?.map(item => item.code);
    return unfilteredPreselectedTickets?.filter(e =>
      publicEventCodes?.includes(e.code!)
    );
  } else if (filteredSortedParams?.id) {
    const publicEventIds = publicEventTickets?.map(item => item.id);
    return unfilteredPreselectedTickets?.filter(e =>
      publicEventIds?.includes(e.id!)
    );
  } else return unfilteredPreselectedTickets;
};

export const formatPrice = (
  number: number,
  currency: (typeof CURRENCY_TYPE)[number]
) =>
  new Intl.NumberFormat(
    LANGUAGE_INTL[i18n.language as (typeof LANGUAGE_TYPE)[number]],
    {
      style: 'currency',
      currency,
    }
  )
    .format(number)
    .replace(/^(\D+)/, '$1 ')
    .replace(/\s+/, ' ');

export const formatDiscountCode = (discountCode: string | undefined) => {
  if (discountCode) {
    return discountCode
      .trim()
      .toUpperCase()
      .replace(/[^a-z0-9]/gi, '');
  }
  return '';
};

export const isNumber = (value: any) =>
  typeof value === 'number' && !isNaN(value);
